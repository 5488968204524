<template>
  <div>
    <Modal :options="{type: 'warning', width: '40vw'}">
      <div class="title">Confirmar pagos</div>
      <div class="body text-center">
        <p class="mensaje_alerta">Estas a punto de enviar a pagar un total de <strong>{{ gastos.length }}</strong> gastos a STP<br/><br/>Es necesario fondear la cuenta con lo menos <strong>${{ total_pagar }}</strong> y asegurarte que el<br/>  monto se encuentra ya disponible por STP</p>
        <p class="mensaje_alerta_pregunta">¿Deseas continuar?</p>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn" @click="pagar_gastos">Pagar</button></div>
          <div class="col-sm-2"><button class="btn danger-btn" @click="cerrar">Cancelar</button></div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import api from '@/apps/tesoreria/api/gastos'

  export default {
    components: {
      Modal
    }
    ,props: {
      gastos: {
        type: Array
        ,default: function() {
          return []
        }
      }
    }
    ,methods: {
      cerrar: function() {
        this.$emit('close');
      }
      ,pagar_gastos: async function() {
        try {
          let payload = [];

          this.gastos.forEach(gasto => {
            payload.push({id: gasto.id, a_pagar: gasto.a_pagar, cuenta_contable: gasto.cuenta_contable});
          })

          let res = (await api.pagar_gastos({gastos: payload})).data;

          this.$emit('update');
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
    ,computed: {
      total_pagar: function() {
        let total = 0;

        this.gastos.forEach(gasto => {
          total += parseFloat(gasto.a_pagar);
        })

        return this.$helper.moneyFormat(total);
      }
    }
  }
</script>