<template>
  <div>
    <Modal :options="{width: '80vw'}" @close="cerrar">
      <div class="title">Pre-pago de gastos</div>
      <div class="body">
        <div class="row mt-4 mb-4">
          <div class="col-sm-2 offset-sm-2"><button class="btn warning-btn" @click="cargar_gastos">Recargar</button></div>
          <div class="col-sm-2"><button class="btn secondary-btn" @click="revisar_gasto">Revisar gasto</button></div>
          <div class="col-sm-2"><button class="btn principal-btn" @click="realizar_pagos">Realizar pago(s)</button></div>
          <div class="col-sm-2"><button class="btn complementary-btn" @click="abrir_pago_externo">Agregar pago externo</button></div>
          <div class="col-sm-2"><button class="btn danger-btn" @click="cerrar">Cancelar</button></div>
        </div>

        <table class="table table-striped table-pagos">
          <thead>
            <th><BSwitch id="sel_all" name="sel_all"/></th>
            <th>Área</th>
            <th>Usuario</th>
            <th>Gasto</th>
            <th>Monto</th>
            <th>Saldo pendiente</th>
            <th>A pagar</th>
            <th>Cuenta contable</th>
            <th>Fecha de envio</th>
            <th>Estatus pago</th>
            <th>Descargar</th>
          </thead>
          <tbody>
            <tr v-for="gasto in gastos" :key="gasto.id" :class="clases_tipo_gasto(gasto.stp_estatus)">
              <td><BSwitch :value="gasto" :id="'gasto_'+gasto.id" :name="'gasto_'+gasto.id" @checked="seleccionar_gasto" :disabled="gasto.stp_estatus == 1"/></td>
              <td>{{ gasto.autorizador.area }}</td>
              <td>{{ gasto.usuario }}</td>
              <td>{{ gasto.gasto }}</td>
              <td>${{ $helper.moneyFormat(parseFloat(gasto.monto).toFixed(2)) }}</td>
              <td>${{ saldo_pendiente(gasto) }}</td>
              <td>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input type="text" :name="'a_pagar_'+gasto.id" :id="'a_pagar_'+gasto.id" :data-id="gasto.id" class="form-control" @change="a_pagar" disabled>
                </div>
              </td>
              <td>
                <select name="" id="" class="form-control" :data-gasto="gasto.id" disabled @change="seleccionar_cuenta_contable">
                  <option value="na">Selecciona la cuenta contable</option>
                  <option v-for="opcion in catalogo.valores" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }} ( {{ opcion.valor }} )</option>
                </select>
              </td>
              <td>{{ $moment(gasto.created_at).format('YYYY-MM-DD HH:mm:ss') }}</td>
              <td>{{ estatus_stp(gasto.stp_estatus) }}</td>
              <td><button v-if="gasto.documentos.length > 0" class="btn btn-primary" @click="descargar_gasto(gasto)">Descargar</button><span v-else>No hay documentos</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>

    <Confirmar v-if="confirmar_pagos" :gastos="seleccionadas" @close="cerrar_confirmar_pagos" @update="recargar_pagos"/>

    <Externo v-if="pago_externo" :gasto="seleccionadas[0]" @close="cerrar_pago_externo" @update="update_pago_externo" />
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'
  import DataGrid from '@/components/DataGridV2'
  import IC from '@/components/Form/InputCompuesto'
  import Confirmar from './Confirmar'
  import BSwitch from '@/components/Form/Switch'
  
  import Externo from './PagoExterno'

  import api from '@/apps/tesoreria/api/gastos'
  import apiCatalogos from '@/apps/tesoreria/api/catalogos'

  export default {
    components: {
      Modal, DataGrid, IC, Confirmar, BSwitch, Externo
    }
    ,data: function() {
      return {
        seleccionar: false
        ,seleccionadas: []
        ,gastos: []
        ,let_input_activos: []
        ,confirmar_pagos: false
        ,pago_externo: false
        ,catalogo: {
          catalogo:null
          ,codigo: null
          ,valores: []
        }
      }
    }
    ,mounted: function() {
      this.cargar_gastos();
      this.cargar_catalogo();
    }
    ,methods: {
      cerrar: function() {
        this.$emit('close');
      }
      ,cargar_gastos: async function() {
        try {
          this.gastos = (await api.obtener_gastos_porpagar()).data;
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cargar_catalogo: async function() {
        try {
          this.catalogo = (await apiCatalogos.buscar_catalogo('cuentas_contables')).data;
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,a_pagar: function(e) {
        for(let i=0; i<this.seleccionadas.length; i++) {
          if (this.seleccionadas[i].id == e.target.dataset.id) {
            let monto = this.seleccionadas[i].monto;

            if (this.seleccionadas[i].pagos.length > 0)
              monto = this.seleccionadas[i].pagos[this.seleccionadas[i].pagos.length-1].saldo_insoluto;

            if (e.target.value > monto){
              e.target.value = parseFloat(monto).toFixed(2);
              return this.$helper.showMessage('Error','El monto a pagar no puede ser mayor al monto del gasto o al saldo pendiente','error','alert');
            }

            this.seleccionadas[i].a_pagar = parseFloat(e.target.value);
          }
        }

        this.actualizar_total();
      }
      ,revisar_gasto: function() {
        if (this.seleccionadas.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionarl el gasto a revisar','error','alert');

        this.$emit('revisar', this.seleccionadas[0]);
      }
      ,cambiar_valor: function(e) {
        let valor = e.target.value;

        if (parseFloat(valor) > parseFloat(e.target.dataset.monto)) {
          e.target.value = this.$helper.moneyFormat(e.target.dataset.monto);
          return this.$helper.showMessage('Error','El monto del pago no puede ser mayor al monto del gasto','error','alert');
        }

        valor = this.$helper.moneyFormat(valor);
        e.target.value = valor;

        this.$log.info('id',e.target.dataset.id);

        for(let i=0; i<this.seleccionadas.length; i++) {
          if (this.seleccionadas[i].id == e.target.dataset.id)
            this.seleccionadas[i].a_pagar = valor;
        }
      }
      ,realizar_pagos: function() {
        try {
          if (this.seleccionadas.length == 0)
            return this.$helper.showMessage('Error','Debes seleccionar al menos un gasto para pagar','error','alert');

          for(let i=0; i<this.seleccionadas.length; i++) {
            if (!this.seleccionadas[i].cuenta_contable || this.seleccionadas[i].cuenta_contable == 'na') {
              this.$helper.showMessage('Error','Debes seleccionar la cuenta contable del gasto "'+this.seleccionadas[i].gasto+'"','error','alert');
              i=this.seleccionadas.length;
              return;
            }
          }

          this.confirmar_pagos = true;
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar_confirmar_pagos: function() {
        this.confirmar_pagos = false;
      }
      ,recargar_pagos: function() {
        let checkboxs = document.getElementsByTagName('input');

        checkboxs.forEach(cb => {
          if (cb.type == 'checkbox' && cb.id.indexOf('gasto_') > -1) {
            if (cb.checked) {
              cb.click();
            }
          }
        })
        
        this.cerrar_confirmar_pagos();
        this.cargar_gastos();
      }
      ,seleccionar_gasto: function(checked, value, id, checkbox) {
        let row = checkbox.parentElement.parentElement.parentElement.parentElement;
        let input = row.children[6].children[0].children[1];
        let select = row.children[7].children[0];

        if (checked) {   
          input.disabled = false;

          let monto = value.monto;

          if (value.pagos.length > 0)
            monto = value.pagos[value.pagos.length-1].saldo_insoluto;

          select.disabled = false;
          select.value = value.cuenta_contable;

          input.value = parseFloat(monto).toFixed(2);
          value.a_pagar = input.value;
          this.seleccionadas.push(value);
        }else {
          input.disabled = true;
          input.value = null;

          select.disabled = true;
          select.value = 'na';

          let seleccionadas = [];

          for(let i=0; i<this.seleccionadas.length; i++) {
            if (this.seleccionadas[i].id != value.id) {
              seleccionadas.push(this.seleccionadas[i]);
            }
          }

          this.seleccionadas = seleccionadas;
        }
      }
      ,estatus_stp: function(estatus) {
        switch(estatus) {
          case 0:
            return 'NA';
            break;
          case 1:
            return 'En transición';
            break;
          case 3:
            return 'Pago pendiente';
            break;
          case 4:
            return 'Error';
            break;
        }
      }
      ,clases_tipo_gasto: function(estatus) {
        switch(estatus) {
          case 1:
            return 'pago_estatus_transicion';
            break;
          case 3:
            return 'pago_estatus_pendiente';
            break;
          case 4:
            return 'pago_estatus_error';
            break;
        }
      }
      ,saldo_pendiente: function(gasto) {
        let saldo_pendiente = 0;

        if (gasto.pagos.length > 0)
          saldo_pendiente = gasto.pagos[gasto.pagos.length-1].saldo_insoluto;

        let float = parseFloat(saldo_pendiente).toFixed(2);
        return this.$helper.moneyFormat(float);
      }
      ,abrir_pago_externo: function() {
        if (this.seleccionadas.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar el gasto a pagar','error','alert');

        if (this.seleccionadas.length > 1)
          return this.$helper.showMessage('Error','Debes seleccionar solo el gasto a pagar','error','alert');

        this.pago_externo = true;
      }
      ,cerrar_pago_externo: function() {
        this.seleccionar = false;
        this.pago_externo = false;

        let checkboxs = document.getElementsByTagName('input');

        checkboxs.forEach(cb => {
          if (cb.type == 'checkbox' && cb.id.indexOf('gasto_') > -1) {
            if (cb.checked) {
              cb.click();
            }
          }
        })
      }
      ,update_pago_externo: function() {
        this.cerrar_pago_externo();
        this.cargar_gastos();
      }
      ,seleccionar_cuenta_contable: function(e) {
        for(let i=0; i<this.seleccionadas.length; i++) {
          if (this.seleccionadas[i].id == e.target.dataset.gasto) {
            this.seleccionadas[i].cuenta_contable = e.target.value;
          }
        }
      }
      ,async descargar_gasto(gasto) {
        try {
          let res = (await api.descargar_documentos_all(gasto.id)).data;

          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement('a');
          link.href = url;
          link.setAttribute('download',gasto.gasto+'.zip');
          document.body.appendChild(link);
          link.click();
        }catch(e) {
          this.$log.info('error: ', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>

<style lang="scss">
  .table-pagos {
    thead {
      background-color: #245671 !important;
    }
  }

  .pago_estatus_transicion {
    background-color: #99CEF9 !important;
  }

  .pago_estatus_pendiente {
    background-color: #676767 !important;
    color: #fff;
  }

  .pago_estatus_error {
    background-color: #E89F9F !important;
  }
</style>